import { Text } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * JobNameコンポーネントのProps
 */
interface Props {
  /** 職種名 */
  jobName: string;
}

/**
 * JobNameコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} JobNameのJSX要素
 */
export const JobName: React.FC<Props> = ({ jobName }) => {
  return (
    <Text
      as="span"
      size="12"
      color="black_pc"
      className={styles.cardDetail__jobName}
    >
      {jobName}
    </Text>
  );
};

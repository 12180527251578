import classnames from 'classnames';
import { MouseEventHandler } from 'react';
import styles from './index.module.scss';

/**
 *  レコメンドカードのサムネイル画像props
 */
interface Props {
  imageUrl: string;
  altText: string;
  jobDetailUrl: string;
  isWide?: boolean;
  onClickThumbnail?: MouseEventHandler<HTMLAnchorElement>;
}

export const Thumbnail: React.FC<Props> = ({
  imageUrl,
  altText,
  jobDetailUrl,
  onClickThumbnail,
  isWide,
}) => {
  const setImage =
    imageUrl ||
    'https://doda.jp/resources/dcfront/Html/img_cmn/img_R60_D_190_01_job03.gif';
  return (
    <a
      href={jobDetailUrl}
      onClick={onClickThumbnail}
      target="_blank"
      rel="noopener"
    >
      <img
        src={setImage}
        alt={altText}
        className={classnames(styles.recommendCard__thumbnailImage, {
          [styles['recommendCard__thumbnailImage--wide']]: isWide,
        })}
      />
    </a>
  );
};

/**
 * 指定の文字数を超えると3点リーダーで省略される関数
 * @param {string} companyName - 会社名
 * @param {boolean} [isNew] - 新着フラグ（任意）
 * @param {boolean} [isDeadline] - 締切フラグ（任意）
 * @returns {string} 省略された会社名
 */
export const omitCompanyName = (
  companyName: string,
  isNew?: boolean,
  isDeadline?: boolean
): string => {
  let maxLength: number;

  const allStatus = isNew && isDeadline;

  if (allStatus) {
    maxLength = 14; // [NEW][締切間近]タグがついた時の文字数制限
  } else if (isNew) {
    maxLength = 18; // [NEW]タグがついた時の文字数制限
  } else if (isDeadline) {
    maxLength = 17; // [締切間近]タグがついた時の文字数制限
  } else {
    maxLength = 22; // [NEW][締切間近]タグが付かない時の文字数制限
  }

  return companyName.length > maxLength
    ? `${companyName.substring(0, maxLength)}…`
    : companyName;
};

import { ArrowLeft } from '@doda/io-react/common';
import { CustomArrowProps } from 'react-slick';

/**
 * PrevArrow の Props
 */
interface Props extends CustomArrowProps {
  className?: string;
  ariaLabel?: string;
}
export const PrevArrow: React.FC<Props> = ({
  className,
  ariaLabel,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className={className}
      aria-label={ariaLabel}
      type="button"
    >
      <ArrowLeft size="S" color="blue" />
    </button>
  );
};

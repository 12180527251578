import { ArrowRight } from '@doda/io-react/common';
import { CustomArrowProps } from 'react-slick';

/**
 * NextArrow の Props
 */
interface Props extends CustomArrowProps {
  className?: string;
  ariaLabel?: string;
}

export const NextArrow: React.FC<Props> = ({
  className,
  ariaLabel,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className={className}
      aria-label={ariaLabel}
      type="button"
    >
      <ArrowRight size="S" color="blue" />
    </button>
  );
};

import { SectionTitle } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * レコメンドスライドのタイトル用Props
 */
interface Props {
  title: string;
  jobCount?: number;
  isNewArrival?: boolean;
}

export const Title: React.FC<Props> = ({ title, jobCount, isNewArrival }) => {
  return isNewArrival && jobCount ? (
    <div>
      <div className={styles.recommendSliderTitle}>
        新着求人
        <span className={styles.recommendSliderTitle_jobCount}>
          {jobCount.toLocaleString()}
        </span>
        件
      </div>
      <p className={styles.recommendSliderUpdateDate}>
        毎週月・木曜に新着求人をお知らせ
      </p>
    </div>
  ) : (
    <SectionTitle bgType="none" size="XL">
      {title}
    </SectionTitle>
  );
};

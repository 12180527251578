import { Location, Income, Text } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * JobDetailsコンポーネントのProps
 */
interface Props {
  /** 勤務地 */
  area: string;
  /** 年収 */
  income: string;
}

/**
 * JobDetailsコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} JobDetailsのJSX要素
 */
export const JobDetails: React.FC<Props> = ({ area, income }) => {
  return (
    <div className={styles.cardDetail__detailsItemWrapper}>
      <span className={styles.cardDetail__detailsItem}>
        <Location size="S" color="blue" />
        <Text as="span" size="12" className={styles.detailsItem__text}>
          {area}
        </Text>
      </span>
      <span className={styles.cardDetail__detailsItem}>
        <Income size="XS" color="blue" />
        <Text as="span" size="12" className={styles.detailsItem__text}>
          {income}
        </Text>
      </span>
    </div>
  );
};

import React, { useRef, ReactNode } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import SlickSlider, { Settings } from 'react-slick';
import styles from './index.module.scss';
import { PrevArrow } from '../PrevArrow';
import { NextArrow } from '../NextArrow';

/**
 * SliderコンポーネントのProps
 */
interface Props {
  /** スライダーに表示する子要素 */
  children: ReactNode;
}

/**
 * Sliderコンポーネント
 * スライダーを表示するためのコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element | null} SliderのJSX要素
 */
export const Slider: React.FC<Props> = ({ children }) => {
  const sliderRef = useRef<SlickSlider>(null);

  const handleClickSliderPrev = () => {
    if (sliderRef?.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleClickSliderNext = () => {
    if (sliderRef?.current) {
      sliderRef.current.slickNext();
    }
  };

  // レコメンドカードの枚数をカウント
  const numChildren = React.Children.count(children);
  const slidesToShow = numChildren > 4 ? 4 : numChildren;

  // レコメンドカードが一つもなければスライダーを非表示
  if (numChildren === 0) {
    return null;
  }

  const settings: Settings = {
    dots: true,
    arrows: false,
    autoplaySpeed: 5000,
    slidesToShow,
    slidesToScroll: 4,
    infinite: true,
    autoplay: true,
    swipe: false,
    pauseOnHover: false,
    className: 'recommendStyle',
    // eslint-disable-next-line react/no-unstable-nested-components
    appendDots: (dots) => (
      <div className={styles.carouselStyle}>
        <PrevArrow
          onClick={handleClickSliderPrev}
          className={styles.prevArrowStyle}
        />
        <ul className={styles.carouselDotsStyle}>{dots}</ul>
        <NextArrow
          onClick={handleClickSliderNext}
          className={styles.nextArrowStyle}
        />
      </div>
    ),
  };

  return (
    <SlickSlider
      ref={sliderRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...settings}
    >
      {children}
    </SlickSlider>
  );
};

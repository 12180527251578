import { ComponentProps } from 'react';
import { RecommendCard } from 'components/RecommendCard';
import {
  Result as RecommendJob,
  Result,
} from '@doda/common/src/interfaces/api/recommendPanel';
import { Title } from './components/Title';
import { SummaryBottom } from './components/SummaryBottom';
import { Slider } from './components/Slider';
import styles from './index.module.scss';

const MAX_CARD_NUMBER = 28;

type RecommendProps = ComponentProps<typeof RecommendCard>;

/**
 * レコメンドスライダー表示用Props
 */
export interface RecommendSliderProps {
  recommendList: Result[];
  onClickFavorite: (recommend: Result, index: number, jid?: string) => void;
  onClickSaved: RecommendProps['onClickSaved'];
  onClickThumbnail?: (
    recommendJob: RecommendJob,
    index: number,
    jid?: string
  ) => void;
  onClickCardDetail?: (
    recommendJob: RecommendJob,
    index: number,
    jid?: string
  ) => void;
  title: string;
  isSummaryBottom?: boolean;
  href?: string;
  additionalQueryString?: string;
  bookmarkListUrl: RecommendProps['bookmarkListUrl'];
  saveBookmarkRequestBody: RecommendProps['saveBookmarkRequestBody'];
  recommendType: string;
  isNewArrival?: boolean;
  jobCount?: number;
  queryParamOnClick?: string;
}

export const RecommendSlider: React.FC<RecommendSliderProps> = ({
  recommendList,
  onClickFavorite,
  onClickSaved,
  onClickThumbnail,
  onClickCardDetail,
  title,
  isSummaryBottom = true,
  href,
  additionalQueryString,
  bookmarkListUrl,
  saveBookmarkRequestBody,
  recommendType,
  isNewArrival = false,
  jobCount,
  queryParamOnClick,
}) => {
  // レコメンドカードが０枚だったらコンポーネント自体を非表示にする
  if (!recommendList || recommendList.length === 0) {
    return null;
  }
  // レコメンドカードの表示枚数は最大28枚まで
  const recommendedCards = recommendList
    ? recommendList.slice(0, MAX_CARD_NUMBER)
    : [];

  return (
    <div>
      <span className={styles.recommendSlider__titleArea}>
        <input
          name="recommendIdx"
          id="recommendIdx"
          className={`recommendIdx${recommendType}`}
          type="hidden"
          value="1"
        />
        <Title title={title} isNewArrival={isNewArrival} jobCount={jobCount} />
        {href && <SummaryBottom isShow={isSummaryBottom} href={href} />}
      </span>
      <Slider>
        {recommendedCards.map((recommend, index) => (
          <RecommendCard
            key={index}
            imageUrl={recommend.imgUrl}
            altText={recommend.companyName}
            companyName={recommend.companyName}
            jobName={recommend.jobName}
            area={recommend.area}
            income={recommend.income}
            jobDetailUrl={recommend.url}
            isNew={recommend.isNew}
            isDeadline={recommend.isDeadline}
            recommendType={recommend.recommendType}
            additionalQueryString={additionalQueryString}
            onClickFavorite={() => {
              if (onClickFavorite)
                onClickFavorite(recommend, index, recommend.itemNo);
            }}
            onClickSaved={onClickSaved}
            onClickThumbnail={() => {
              if (onClickThumbnail)
                onClickThumbnail(recommend, index, recommend.itemNo);
            }}
            onClickCardDetail={() => {
              if (onClickCardDetail)
                onClickCardDetail(recommend, index, recommend.itemNo);
            }}
            bookmarkListUrl={bookmarkListUrl}
            jid={recommend.itemNo}
            saveBookmarkRequestBody={saveBookmarkRequestBody}
            isBookmarked={recommend.saveBookmark}
            queryParamOnClick={queryParamOnClick}
          />
        ))}
      </Slider>
    </div>
  );
};

/**
 * url にクエリパラメータを追加して返す関数
 * @param url - クエリを追加する対象のベースURL
 * @param queries - 追加するクエリパラメータ
 * @returns クエリパラメータが追加されたURL
 */
export const addQueriesToUrl = (url: string, queries: string) => {
  if (!queries) return url;

  const hasQuery = new Set(url).has('?');
  return url + (hasQuery ? '&' : '?') + queries;
};

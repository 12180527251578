import { FillTag, OutlineTag } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * JobDetailStatusコンポーネントのProps
 */
interface Props {
  /** 新着フラグ（任意） */
  isNew?: boolean;
  /** 締切フラグ（任意） */
  isDeadline?: boolean;
}

/**
 * JobDetailStatusコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} JobDetailStatusのJSX要素
 */
export const JobDetailStatus: React.FC<Props> = ({ isNew, isDeadline }) => {
  return (
    <>
      {isNew && (
        <FillTag color="red" className={styles.companyName__jobDetailStatus}>
          NEW
        </FillTag>
      )}
      {isDeadline && (
        <OutlineTag
          color="blue"
          className={styles.companyName__jobDetailStatus}
        >
          締切間近
        </OutlineTag>
      )}
    </>
  );
};

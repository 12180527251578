import classnames from 'classnames';
import { Text, ArrowRight } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * 一覧画面へ遷移するために必要なprops
 */
interface Props {
  /** 遷移先href*/
  href?: string;
  /** 表示判定フラグ*/
  isShow?: boolean;
}

export const SummaryBottom: React.FC<Props> = ({ href, isShow }) => {
  return (
    <a
      href={href}
      className={classnames([
        { [styles['summaryBottom--visible']]: isShow === true },
        { [styles['summaryBottom--invisible']]: isShow === false },
      ])}
    >
      <Text
        color="blue"
        size="14--articlePC"
        className={styles.summaryBottom__text}
      >
        一覧へ
      </Text>
      <ArrowRight size="XS" color="blue" />
    </a>
  );
};

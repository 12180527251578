import { Box } from '@doda/io-react/common';
import { Favorite } from 'components/Favorite';
import classnames from 'classnames';
import { ComponentProps } from 'react';
import styles from './index.module.scss';
import { Thumbnail } from './components/Thumbnail';
import { CompanyName } from './components/CompanyName';
import { JobName } from './components/JobName';
import { JobDetails } from './components/JobDetails';
import { addQueriesToUrl } from '../../pages/DodaFront/View/Top/logics/addQueriesToUrl';

type FavoriteProps = ComponentProps<typeof Favorite>;

/**
 * レコメンドカードProps
 * @param {string} param.imageUrl サムネイル画像
 * @param {string} param.altText サムネイル画像alt
 * @param {string} param.companyName 企業名
 * @param {string} param.jobName 職種
 * @param {string} param.area 勤務地
 * @param {string} param.income 給与
 * @param {string} param.jobDetailUrl 求人データ
 * @param {boolean} param.isJobDetails [職種][給与]の表示有無
 * @param {string} param.bookmarkListUrl 気になるリストのURL
 * @param {boolean} param.isNew [NEW]タグ表示有無
 * @param {boolean} param.isDeadline [締切間近]タグ表示有無
 * @param {string} param.recommendType レコメンドタイプ
 * @param {string} param.additionalQueryString 追加パラメータ
 * @param {Void} param.onClickFavorite [気になる]ボタン押下時に発火
 * @param {Void} param.onClickSaved [気になるリストへ]ボタン押下時に発火
 * @param {Void} param.onClickThumbnail サムネイル押下時に発火
 * @param {Void} param.onClickCardDetail [会社名][職種]部分押下時に発火
 * @param {string} param.jid 求人ID
 * @param {object} param.saveBookmarkRequestBody 気になる登録APIに必要なパラメータ
 * @param {string} param.shadowColor 影の色 背景が青の時はblue、白・灰色の時はblackを指定
 * @param {boolean} param.isBookmarked 気になる済みかどうか
 * @param {string} param.queryParamOnClick クリック時に付与するクエリパラメータ
 */
interface Props {
  imageUrl: string;
  altText: string;
  companyName: string;
  jobName: string;
  area: string;
  income: string;
  jobDetailUrl: string;
  isJobDetails?: boolean;
  bookmarkListUrl: FavoriteProps['bookmarkListUrl'];
  onClickFavorite: FavoriteProps['onClickFavorite'];
  onClickSaved: FavoriteProps['onClickSaved'];
  onClickThumbnail?: () => void;
  onClickCardDetail?: () => void;
  isNew?: boolean;
  isDeadline?: boolean;
  recommendType?: string;
  additionalQueryString?: string;
  jid: FavoriteProps['jid'];
  saveBookmarkRequestBody: FavoriteProps['requestBody'];
  shadowColor?: 'blue' | 'black';
  isBookmarked?: FavoriteProps['isBookmarked'];
  isWide?: boolean;
  queryParamOnClick?: string;
}

/**
 * レコメンドカード
 * @param {string} imageUrl サムネイル画像
 * @param {string} altText サムネイル画像alt
 * @param {string} companyName 企業名
 * @param {string} jobName 職種
 * @param {string} area 勤務地
 * @param {string} income 給与
 * @param {string} jobDetailUrl 求人データ
 * @param {boolean} isJobDetails [職種][給与]の表示有無
 * @param {string} bookmarkListUrl 気になるリストのURL
 * @param {boolean} isNew [NEW]タグ表示有無
 * @param {boolean} isDeadline [締切間近]タグ表示有無
 * @param {string} recommendType レコメンドタイプ
 * @param {string} additionalQueryString 追加パラメータ
 * @param {Void} onClickFavorite [気になる]ボタン押下時に発火
 * @param {Void} onClickSaved [気になるリストへ]ボタン押下時に発火
 * @param {Void} onClickThumbnail サムネイル押下時に発火
 * @param {Void} onClickCardDetail [会社名][職種]部分押下時に発火
 * @param {string} jid 求人ID
 * @param {object} saveBookmarkRequestBody 気になる登録APIに必要なパラメータ
 * @param {string} shadowColor 影の色 背景が青の時はblue、白・灰色の時はblackを指定
 * @param {boolean} isBookmarked 気になる済みかどうか
 * @param {boolean} isWide カード幅がワイドか
 */
export const RecommendCard: React.FC<Props> = ({
  imageUrl,
  altText,
  companyName,
  jobName,
  area,
  income,
  jobDetailUrl,
  isJobDetails = true,
  bookmarkListUrl,
  isNew,
  isDeadline,
  recommendType,
  additionalQueryString,
  onClickFavorite,
  onClickSaved,
  onClickThumbnail,
  onClickCardDetail,
  jid,
  saveBookmarkRequestBody,
  shadowColor = 'blue',
  isBookmarked = false,
  isWide = false,
  queryParamOnClick,
}) => {
  // urlにrecommendTypeをセット
  const baseUrl = `${jobDetailUrl}?recommendID=${recommendType}`;
  // 追加でクエリパラメータがあればurlにセットされる
  const url = additionalQueryString
    ? `${baseUrl}&${additionalQueryString}`
    : `${baseUrl}`;
  return (
    <Box
      color="white"
      boxShadow={shadowColor}
      radius="4"
      className={classnames(styles.recommendCard, {
        [styles['recommendCard--wide']]: isWide,
      })}
    >
      <Thumbnail
        imageUrl={imageUrl}
        altText={altText}
        jobDetailUrl={url}
        onClickThumbnail={(event) => {
          if (onClickThumbnail) onClickThumbnail();
          if (queryParamOnClick) {
            event.currentTarget.href = addQueriesToUrl(url, queryParamOnClick);
          }
        }}
        isWide={isWide}
      />
      <Box
        className={classnames(styles.recommendCard__cardDetail, {
          [styles['recommendCard--isJobDetails']]: isJobDetails,
          [styles['recommendCard--notJobDetails']]: !isJobDetails,
        })}
      >
        <a
          href={url}
          onClick={(event) => {
            if (onClickCardDetail) onClickCardDetail();
            if (queryParamOnClick) {
              event.currentTarget.href = addQueriesToUrl(
                url,
                queryParamOnClick
              );
            }
          }}
          className={classnames(styles.cardDetail__link, {
            [styles['jobDetails--invisible']]: isJobDetails === false,
          })}
          target="_blank"
          rel="noopener"
        >
          <CompanyName
            companyName={companyName}
            isNew={isNew}
            isDeadline={isDeadline}
          />
          <JobName jobName={jobName} />
        </a>
        {isJobDetails && <JobDetails area={area} income={income} />}
      </Box>
      <Favorite
        jid={jid}
        requestBody={saveBookmarkRequestBody}
        size="S"
        width="100%"
        fadeTrigger="timer"
        isBookmarked={isBookmarked}
        bookmarkListUrl={bookmarkListUrl}
        onClickFavorite={onClickFavorite}
        onClickSaved={onClickSaved}
      />
    </Box>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

interface ImpressionAnalyticsProps {
  adobe?: VoidFunction;
  google?: VoidFunction;
}

export const ImpressionAnalytics = ({
  adobe,
  google,
}: ImpressionAnalyticsProps) => {
  useEffect(() => {
    if (adobe) adobe();
  }, []);

  useEffect(() => {
    if (google) google();
  }, []);

  return null;
};
